import "../GlobalStyle.css";
import "../PCStyle.css";
import RequiredDocumentInfo from "../Assets/Images/RequiredDocumentInfo.png";

const LK02RequiredDocumentInfo = () => {
  return (
    <>
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "90%", maxWidth: "1520px" }}>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", width: "100%", marginTop: "48px" }}>
          <div className="Link_PageDescription_Copy">써티드 앱에서 필수 서류 전달 하기</div>
          <div className="Link_PageDescription_Divider" />
          <div className="Link_Title_CT">
            <div className="Link_Title_Copy">필수 서류 전송 방법</div>
            <div className="Link_Body_Copy">필수 서류 요청 문자메시지를 받으시면 써티드 앱 설치 후, 기업이 요청한 필수 서류를 등록하고 전송할 수 있습니다.</div>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
          <img src={RequiredDocumentInfo} alt="RequiredDocumentInfo" style={{ width: "1104px", height: "811px", marginBottom: "96px" }} />
        </div>
      </div>
    </>
  );
};

export default LK02RequiredDocumentInfo;
