import "../GlobalStyle.css";
import "../PCStyle.css";
import { useState, useEffect } from "react";
import InputShort from "../Components/InputShort";
import { useParams } from "react-router-dom";
import axios from "axios";
import Loading from "../Components/Loading";
import * as amplitude from "@amplitude/analytics-browser";
import Icon from "../Assets/Icons/Icon";
import { useNavigate } from "react-router-dom";

const LK01RequiredDocumentLink = () => {
  const { token } = useParams();
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [requestData, setRequestData] = useState(null);
  const [sendDocument, setSendDocument] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const loadRequestData = async () => {
      const endPoint = "https://dml1u35aa3.execute-api.ap-northeast-2.amazonaws.com/product/recipient/link";
      setIsLoading(true);
      try {
        const response = await axios.get(endPoint, {
          params: { token: token },
        });
        if (response.data.status === "링크 정보 리턴 성공") {
          setRequestData(response.data.data);

          const requiredDocument = [];

          if (response.data.data.documents.some((doc) => doc.documentType === "certificate") && !requiredDocument.some((doc) => doc.documentType === "certificate")) {
            requiredDocument.push({ documentType: "certificate" });
          }
          if (response.data.data.documents.some((doc) => doc.documentType === "resume") && !requiredDocument.some((doc) => doc.documentType === "resume")) {
            requiredDocument.push({ documentType: "resume" });
          }
          if (response.data.data.documents.some((doc) => doc.documentType === "identityCardImage") && !requiredDocument.some((doc) => doc.documentType === "identityCardImage")) {
            requiredDocument.push({ documentType: "identityCardImage" });
          }
          if (response.data.data.documents.some((doc) => doc.documentType === "diploma") && !requiredDocument.some((doc) => doc.documentType === "diploma")) {
            requiredDocument.push({ documentType: "diploma" });
          }
          if (
            response.data.data.documents.some((doc) => doc.documentType === "recentTwoYearIncomeReceipt") &&
            !requiredDocument.some((doc) => doc.documentType === "recentTwoYearIncomeReceipt")
          ) {
            requiredDocument.push({ documentType: "recentTwoYearIncomeReceipt" });
          }
          if (
            response.data.data.documents.some((doc) => doc.documentType === "recentThreeMonthSalaryStatement") &&
            !requiredDocument.some((doc) => doc.documentType === "recentThreeMonthSalaryStatement")
          ) {
            requiredDocument.push({ documentType: "recentThreeMonthSalaryStatement" });
          }
          setSendDocument(requiredDocument);
          setIsLoading(false);
        } else {
          alert("필수 서류 요청 확인에 실패하였습니다. 다시 시도해주세요.");
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error : ", error);
        alert("필수 서류 요청 확인 통신을 실패하였습니다. 다시 시도해주세요.");
        setIsLoading(false);
      }
    };

    if (token && !requestData) {
      loadRequestData();
    }
  }, [requestData, token]);

  const validatePhoneNumber = (phoneNumber) => {
    if (phoneNumber.startsWith("010")) {
      return phoneNumber.length === 11;
    }
    const validPrefixes = ["011", "016", "017", "018", "019"];
    if (validPrefixes.some((prefix) => phoneNumber.startsWith(prefix))) {
      return phoneNumber.length === 10;
    }

    return false;
  };

  const documentTypeMap = {
    certificate: "경력증명서",
    resume: "이력서",
    identityCardImage: "주민등록증 이미지",
    diploma: "졸업증명서",
    recentTwoYearIncomeReceipt: "최근 2년간 근로소득 원천징수 영수증",
    recentThreeMonthSalaryStatement: "최근 3개월간 급여명세서",
  };

  const generateDocumentList = (documents) => {
    if (!Array.isArray(documents) || documents.length === 0) {
      return "문서가 없습니다.";
    }

    const orderedTypes = Object.keys(documentTypeMap)
      .filter((key) => documents.some((doc) => doc.documentType === key))
      .map((key) => `- ${documentTypeMap[key]}`);

    if (orderedTypes.length === 0) {
      return "관련 문서가 없습니다.";
    }

    return orderedTypes.join("<br />");
  };

  const result = generateDocumentList(sendDocument);

  const moveToLandingPage = () => {
    amplitude.track("랜딩페이지 진입");
    window.open("https://www.certifie.io", "_blank", "noopener noreferrer");
  };

  const sendMessage = async () => {
    setIsLoading(true);
    if (name.length === 0 || !validatePhoneNumber(phoneNumber)) {
      alert("이름과 전화번호를 정확히 입력해주세요.");
      setIsLoading(false);
      return;
    } else {
      const endPoint = "https://dml1u35aa3.execute-api.ap-northeast-2.amazonaws.com/product/recipient/link/message";
      try {
        const response = await axios.post(endPoint, {
          companyName: requestData.companyName,
          companyEmail: requestData.companyEmail,
          recipientName: name,
          recipientTelNumber: phoneNumber,
          groupName: requestData.groupName,
          groupId: requestData.groupId,
          certificate: sendDocument.some((doc) => doc.documentType === "certificate"),
          resume: sendDocument.some((doc) => doc.documentType === "resume"),
          identityCardImage: sendDocument.some((doc) => doc.documentType === "identityCardImage"),
          diploma: sendDocument.some((doc) => doc.documentType === "diploma"),
          recentTwoYearIncomeReceipt: sendDocument.some((doc) => doc.documentType === "recentTwoYearIncomeReceipt"),
          recentThreeMonthSalaryStatement: sendDocument.some((doc) => doc.documentType === "recentThreeMonthSalaryStatement"),
        });

        if (response.data.status === "전송 성공") {
          alert("필수 서류 요청 메시지를 전송했습니다.");
          navigate(`/link/${token}/information`);
          setIsLoading(false);
        } else {
          alert("필수 서류 요청 메시지 전송에 실패하였습니다. 다시 시도해주세요.");
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error : ", error);
        alert("필수 서류 요청 메시지 전송 통신을 실패하였습니다. 다시 시도해주세요.");
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      {isLoading ? <Loading /> : null}
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "90%", maxWidth: "1520px" }}>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", width: "100%", marginTop: "48px" }}>
          <div className="Link_PageDescription_Copy">써티드 앱에서 필수 서류 전달 하기</div>
          <div className="Link_PageDescription_Divider" />
          <div className="Link_Title_CT">
            <div className="Link_Title_Copy">
              {requestData?.companyName || "기업"}에서 요청한 필수 서류를
              <br />
              써티드 앱에서 전송해주세요.
            </div>
            <div className="Link_Body_Copy">
              이름과 전화번호를 입력한 뒤 메시지 전송을 실행하시면 기업이 요청한 필수 서류와
              <br />
              써티드 앱 다운로드 링크가 담긴 메시지가 전송됩니다.
              <br />
              링크를 통해 앱을 다운받은 뒤 써티드 앱에서 필수 서류를 전송해주세요.
            </div>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
          {requestData && (
            <>
              <div className="Link_RequiredDocument_Title_Copy">필수 서류</div>
              <div className="Link_RequiredDocument_List_BG">
                <div className="Link_RequiredDocument_List_Copy" dangerouslySetInnerHTML={{ __html: result }}></div>
              </div>
            </>
          )}
          <InputShort
            placeholder={"이름을 입력해주세요."}
            type={"text"}
            required
            requiredCondition={name.length > 0 ? true : false}
            label={"이름"}
            value={name}
            onChange={setName}
          />
          <InputShort
            placeholder={"전화번호를 입력해주세요. (- 없이 입력)"}
            required
            requiredCondition={validatePhoneNumber(phoneNumber)}
            label={"전화번호"}
            type={"text"}
            value={phoneNumber}
            onChange={setPhoneNumber}
            marginBottom={"0px"}
          />
          <button className="Link_Button_CT" onClick={sendMessage}>
            메시지 전송
          </button>
          <button className="Link_TextButton_CT" onClick={moveToLandingPage}>
            써티드 알아보기
            <Icon name={"ArrowForward"} size={16} color={"#3769fc"} />
          </button>
        </div>
      </div>
    </>
  );
};

export default LK01RequiredDocumentLink;
