import "./GlobalStyle.css";
import "./PCStyle.css";
import "./TabletStyle.css";
import "./MobileStyle.css";
import "../src/index.css";
import React from "react";
import GNB from "./Components/GNB";
import AD01Login from "./Pages/AD01Login";
import AD02RequestList from "./Pages/AD02RequestList";
import AD02RequestListMobile from "./Pages/AD02RequestListMobile";
import AD03RequestDetailMobile from "./Pages/AD03RequestDetailMobile";
import EM01ReceiveCertification from "./Pages/EM01ReceiveCertification";
import RQ01RequestIntroduction from "./Pages/RQ01RequestIntroduction";
import RQ02RequestDescription from "./Pages/RQ02RequestDescription";
import RQ03RequestProgress from "./Pages/RQ03RequestProgress";
import RQ04RequestProgressComplete from "./Pages/RQ04RequestProgressComplete";
import RQ05RequestReject from "./Pages/RQ05RequestReject";
import RQ06RequestRejectComplete from "./Pages/RQ06RequestRejectComplete";
import PLSelectMemberType from "./Pages/PLSelectMemberType";
import PSRegisterProcess from "./Pages/PSRegisterProcess";
import LK01RequiredDocumentLink from "./Pages/LK01RequiredDocumentLink";
import LK02RequiredDocumentInfo from "./Pages/LK02RequiredDocumentInfo";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { observer } from "mobx-react";

const App = observer(() => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<AD01Login />} />
        <Route path="/plus" element={<PLSelectMemberType />} />
        <Route path="/plus/process" element={<PSRegisterProcess />} />
        <Route path="/link/:token" element={<LK01RequiredDocumentLink />} />
        <Route path="/link/:token/information" element={<LK02RequiredDocumentInfo />} />
        <Route
          path="/*"
          element={
            <>
              <GNB />
              <Routes>
                <Route path="/requestlist/:id" element={<AD02RequestList />} />
                <Route path="/requestlist/:id/M" element={<AD02RequestListMobile />} />
                <Route path="/requestlist/:id/M/detail" element={<AD03RequestDetailMobile />} />
                <Route path="/certificate/:token" element={<EM01ReceiveCertification />} />
                <Route path="/email/info/:token" element={<RQ01RequestIntroduction />} />
                <Route path="/email/:token" element={<RQ02RequestDescription />} />
                <Route path="/email/:id/issue" element={<RQ03RequestProgress />} />
                <Route path="/email/:id/issue/complete" element={<RQ04RequestProgressComplete />} />
                <Route path="/email/:id/reject" element={<RQ05RequestReject />} />
                <Route path="/email/:id/reject/complete" element={<RQ06RequestRejectComplete />} />
              </Routes>
            </>
          }
        />
      </Routes>
    </BrowserRouter>
  );
});

export default App;
