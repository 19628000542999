import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import Footer from "../Components/Footer";

const AD01Login = observer(() => {
  const navigate = useNavigate();
  const screenWidth = window.innerWidth;

  const [companyId, setCompanyId] = useState("");
  const [companyPassword, setCompanyPassword] = useState("");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleLoginComplete = async () => {
    if (companyId === "certedadmin" && companyPassword === "20240101") {
      navigate("/requestlist/admin", {
        state: { companyId: companyId, companyPassword: companyPassword },
      });
    }
  };

  // const onClickPlus = async () => {
  //   navigate("/plus");
  // };

  const handleLoginCompleteMobile = async () => {
    if (companyId === "certedadmin" && companyPassword === "20240101") {
      navigate("/requestlist/admin/M", {
        state: { companyId: companyId, companyPassword: companyPassword },
      });
    } else {
      alert("아이디 또는 비밀번호가 맞지 않습니다.\n\n다시 한번 확인해주세요.");
    }
  };

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // const sealTest = async () => {
  //   navigate(`/email/info/${testToken}`, {
  //     state: { token: testToken },
  //   });
  // };

  // const sealTest = async () => {
  //   navigate(
  //     `/link/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InJvb3RAZ2Rwc3R1ZGlvLmlvIiwibGlua0lkIjoiOTViMGNmZmEtNTQzNS00MDhkLWEwM2YtN2Y2OGYxNDhmYjMwIiwiZ3JvdXBJZCI6IjNhYjI1ZDA5LWFiZDctNDdlYS04ZTgxLTZiY2VmODU3ZjcwMCIsImdyb3VwTmFtZSI6ImlPUyDthYzsiqTtirgiLCJjcmVhdGlvbkRhdGUiOiIyMDI0LTEyLTI0VDAxOjE5OjQ2Ljc0NFoiLCJleHBpcmF0aW9uUGVyaW9kIjoiMjAyNS0wMS0yM1QwMToxOTo0Ni43NDRaIiwiZG9jdW1lbnRzIjpbeyJkb2N1bWVudFR5cGUiOiJjZXJ0aWZpY2F0ZSJ9LHsiZG9jdW1lbnRUeXBlIjoicmVzdW1lIn0seyJkb2N1bWVudFR5cGUiOiJpZGVudGl0eUNhcmRJbWFnZSJ9LHsiZG9jdW1lbnRUeXBlIjoiZGlwbG9tYSJ9LHsiZG9jdW1lbnRUeXBlIjoicmVjZW50VHdvWWVhckluY29tZVJlY2VpcHQifSx7ImRvY3VtZW50VHlwZSI6InJlY2VudFRocmVlTW9udGhTYWxhcnlTdGF0ZW1lbnQifV0sImlhdCI6MTczNTAwMzE4NiwiZXhwIjoxNzM3NTk1MTg2fQ.TF_qvIAxRBLimTCKuRzDteLsGbBwW6FsTJeicTylhUE`,
  //     {
  //       state: {
  //         token:
  //           "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InJvb3RAZ2Rwc3R1ZGlvLmlvIiwibGlua0lkIjoiOTViMGNmZmEtNTQzNS00MDhkLWEwM2YtN2Y2OGYxNDhmYjMwIiwiZ3JvdXBJZCI6IjNhYjI1ZDA5LWFiZDctNDdlYS04ZTgxLTZiY2VmODU3ZjcwMCIsImdyb3VwTmFtZSI6ImlPUyDthYzsiqTtirgiLCJjcmVhdGlvbkRhdGUiOiIyMDI0LTEyLTI0VDAxOjE5OjQ2Ljc0NFoiLCJleHBpcmF0aW9uUGVyaW9kIjoiMjAyNS0wMS0yM1QwMToxOTo0Ni43NDRaIiwiZG9jdW1lbnRzIjpbeyJkb2N1bWVudFR5cGUiOiJjZXJ0aWZpY2F0ZSJ9LHsiZG9jdW1lbnRUeXBlIjoicmVzdW1lIn0seyJkb2N1bWVudFR5cGUiOiJpZGVudGl0eUNhcmRJbWFnZSJ9LHsiZG9jdW1lbnRUeXBlIjoiZGlwbG9tYSJ9LHsiZG9jdW1lbnRUeXBlIjoicmVjZW50VHdvWWVhckluY29tZVJlY2VpcHQifSx7ImRvY3VtZW50VHlwZSI6InJlY2VudFRocmVlTW9udGhTYWxhcnlTdGF0ZW1lbnQifV0sImlhdCI6MTczNTAwMzE4NiwiZXhwIjoxNzM3NTk1MTg2fQ.TF_qvIAxRBLimTCKuRzDteLsGbBwW6FsTJeicTylhUE",
  //       },
  //     }
  //   );
  // };

  return (
    <>
      <div className="FlexContent_Login">
        <div className="AllContent">
          <div className="Login_Head_Copy">관리자 로그인</div>
          <input
            className="Login_Input_BG"
            placeholder="아이디를 입력 해주세요."
            value={companyId}
            style={{ marginBottom: windowWidth > 767 ? "12px" : "2px" }}
            onChange={(e) => setCompanyId(e.target.value)}
            required
          />
          <input
            className="Login_Input_BG"
            placeholder="비밀번호를 입력 해주세요."
            value={companyPassword}
            onChange={(e) => setCompanyPassword(e.target.value)}
            required
            type="password"
          />
          <div className="Login_Button_One_CT">
            <button className="LoginButton_Copy_CT" onClick={windowWidth > 767 ? handleLoginComplete : handleLoginCompleteMobile}>
              <div className="LoginButton_One_BG">
                <div className="LoginButton_Copy">로그인</div>
              </div>
            </button>
            {/* <button className="LoginButton_Copy_CT" onClick={onClickPlus}>
              <div className="LoginButton_One_BG">
                <div className="LoginButton_Copy">플러스</div>
              </div>
            </button> */}
          </div>

          {/* <div className="Login_Button_One_CT">
            <button className="LoginButton_Copy_CT" onClick={sealTest}>
              <div className="LoginButton_One_BG">
                <div className="LoginButton_Copy">이메일 테스트</div>
              </div>
            </button>
          </div> */}
        </div>
        {screenWidth > 1023 && <Footer />}
      </div>
    </>
  );
});

export default AD01Login;
